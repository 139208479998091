import styled from "vue-styled-components";
const pprops = {
  status: String
};

const StyledP = styled("p", pprops)`
 margin-bottom: 0;
 font-weight: bold;
 color: ${props => props.status === 'Registrada' ? 'orange' : null};
 color: ${props => props.status === 'Confirmada' ? 'green' : null};
 color: ${props => props.status === 'No Show' ? 'blue' : null};
 color: ${props => props.status === 'Checkin' ? 'pink' : null};
 color: ${props => props.status === 'Checkout' ? 'brown' : null};
 color: ${props => props.status === 'Cancelada' ? 'red' : null};


`;

export default StyledP;