 <template>
  <div class="wrapper">
    <div class="container">
      <section class="section-score">
        <div class="column-1">
          <div>
            <h1>{{nomehotel}}</h1>
          </div>
          <span>
            <unicon fill="#69CBF2" name="search" />
            <div style="width:100%;">

            <Dropdown
              :options="hoteis"
              :multiple="false"
              placeholder="Selecione um hotel"
              v-model="hotelselecionado"
              :sideEffect="alteracaoCampoHoteis"
              border="none"
              :initialValue="hotelselecionado"
            />
            </div>
          </span>
        </div>
        <!-- <div class="column-2">
          <span>
            <unicon fill="#69CBF2" name="calender" />
            <div>
              <h1>filtrar por datas</h1>
              <p>01/11 - 01/12</p>
            </div>
            <div class="grow" />
            <unicon fill="#69CBF2" name="angle-down" />
          </span>
        </div> -->
        <div class="panel-score">
          <div class="plans">
            <div class="plan">
              <unicon :fill="nivel === 3 ? '#FFEE54' : '#EEEEEE'" height="58" width="58" name="trophy" />
              <div class="plan-info">
                <h1>ouro</h1>
                <p>total de pontos</p>
              </div>
            </div>
            <div class="plan">
              <unicon :fill="nivel === 2 ? '#C2C2C2' : '#EEEEEE'" height="58" width="58" name="award" />
              <div class="plan-info">
                <h1>prata</h1>
                <p>total de pontos</p>
              </div>
            </div>
            <div class="plan">
              <unicon :fill="nivel === 1 ? '#F0B092' : '#EEEEEE'" height="58" width="58" name="award-alt" />
              <div class="plan-info">
                <h1>bronze</h1>
                <p>total de pontos</p>
              </div>
            </div>
          </div>
          <div class="score" v-if="pontosdisponiveis">
            <div class="hero">
              <h1 class="hero-title" @click="() => link('/list/score')">{{pontosdisponiveis}}</h1>
              <h3 class="hero-subtitle">Pontos para resgate</h3>
              <button type="button" @click="() => link('/register/request')">resgatar</button>
            </div>
          </div>
        </div>
        <div class="panel-advantage">
          <h1>Vantagens Disponiveis</h1>
          <p>Você possui {{vantagens.length}} vantagens para resgatar</p>
          <ul>
            <li v-for="(item, idx) in vantagens" :key="idx">
              <span>
                <div class="alt-icon">
                  <unicon name="gift" fill="#0f88f2"/>
                </div>
                <span class="vantagem-info">
                  <h1>{{ item.name }}</h1>
                </span>
              </span>
              <button type="button" :disabled="!item.available" :class="!item.available && 'disabled'" @click="() => link('/register/request')">
                resgatar
              </button>
            </li>
          </ul>
        </div>
      </section>

      <section class="section-request">
        <div class="column-1" v-if="reservas.length">
          <div>
            <h1>últimas solicitações</h1>
          </div>
        </div>

        <div class="panel-request" v-if="reservas.length">
          <v-data-table
            :headers="headers"
            :items="reservas"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            @click:row.self="
              (item) => link(`/list/reserve/${item.idreserva}&${item.idhotel}`)
            "
          >
            <template v-slot:item.idreserva="{ item }">
              <span class="text-body text-h5">
                {{ item.idreserva }}
              </span>
            </template>

            <template v-slot:item.nomehospede="{ item }">
              <span class="d-flex flex-column align-start">
                <span class="item-nomehospede">
                  {{ item.nomehospede }}
                </span>
                <span class="item-nomeagencia">
                  {{ item.nomeagencia }}
                </span>
              </span>
            </template>

            <template v-slot:item.datacadastro="{ item }">
              <span class="text-body text-h5">
                {{ formatarDataHoras(item.datacadastro) }}
              </span>
            </template>

            <template v-slot:item.reservastatus="{ item }">
              <v-chip
                :color="corDaReserva(item.reservastatus)"
                light
                class="chip-reserva"
              >
                <Paragraph :status="item.reservastatus">
                  <v-icon class="icone-reserva" size="16" color="inherit">{{
                    item.icone
                  }}</v-icon>
                  {{ item.reservastatus }}</Paragraph
                >
              </v-chip>
            </template>

            <template v-slot:item.datacheckin="{ item }">
              <span class="text-body text-h5">
                {{ formatarData(item.datacheckin) }}
              </span>
            </template>
            <template v-slot:item.datacheckout="{ item }">
              <span class="text-body text-h5">
                {{ formatarData(item.datacheckout) }}
              </span>
            </template>
          </v-data-table>
          <v-card-actions class="card-padding py-0">
            <v-row>
              <v-col cols="6" lg="5" class="d-flex align-center">
                <span class="text-body me-3">Items per page:</span>
                <v-text-field
                  hide-details
                  type="number"
                  outlined
                  min="-1"
                  max="15"
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  light
                  :value="itemsPerPage"
                  @input="itemsPerPage = parseInt($event, 10)"
                  placeholder="Items per page"
                  class="
                    font-size-input
                    placeholder-lighter
                    text-color-light
                    input-alternative input-focused-alternative input-icon
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="ml-auto d-flex justify-end">
                <v-pagination
                  prev-icon="fa fa-angle-left"
                  next-icon="fa fa-angle-right"
                  class="pagination"
                  color="#0f88f2"
                  v-model="page"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-actions>
        </div>

        <div class="panel-promoter">
          <div class="panel-promoter-header">
            <span class="promoter-header-info">
              <h1>Promotor por região</h1>
              <p>Sua lista com {{ promotores.length }} promotores</p>
            </span>
            <span
              @click="link('/register/promoter')"
              class="promoter-header-action"
              >+</span
            >
          </div>
          <ul>
            <li v-for="(item, idx) in promotores" :key="idx">
              <span>
                <span>{{ item.icone }}</span>
                <div class="alt-icon" v-if="!item.icone" />
                <span class="promoter-info">
                  <h1>{{ item.nome }}</h1>
                  <div class="promoter-info-details">
                    <input
                      v-mask="'+55 (##) #####-####'"
                      v-model="promotores[idx].celular"
                      disabled
                    />
                  </div>
                </span>
              </span>
              <a :href="`https://web.whatsapp.com/send?phone=${numeroTelefone(promotores[idx].celular)}`" target="_blank">
                <unicon name="whatsapp" fill='#187fd9'/>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ReservaServico from "../../servicos/reserva.servico";
import PromotorServico from "../../servicos/promotor.servico";
import HotelServico from "../../servicos/hotel.servico";
import Dropdown from "../../components/Dropdown.vue";
import Paragraph from "../../components/StyledP.js";
import LocalizacaoServico from '../../servicos/localizacao.servico.js'
import UsuarioServico from '../../servicos/usuario.servico.js'
import PremioServico from '../../servicos/premio.servico.js'

export default {
  name: "Dashboard",
  components: { Dropdown, Paragraph },
  data: function () {
    return {
      vantagens: [],
      promotores: [],
      hoteis: [],
      hotelselecionado: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 9,
      checkboxModel: [],
      reservas: [],
      currReserva: {},
      dialogAction: false,
      headers: [
        { text: "Reserva", value: "idreserva" },
        { text: "Solicitação", value: "datacadastro" },
        { text: "Cliente", value: "nomehospede" },
        { text: "Checkin", value: "datacheckin" },
        { text: "Checkout", value: "datacheckout" },
        { text: "Status", value: "reservastatus" },
      ],
      zonas: [],
      pontosdisponiveis: null,
      idusuario: null,
      nomehotel: null,
      nivel: null,
      idusuario: null,
      idusuariotipo: null
    };
  },
  watch: {
    reservas() {
      this.reservas.map((reserva, idx) => {
        if (reserva.reservastatus.toLowerCase() === "registrada") {
          reserva["icone"] = "ni ni-single-copy-04";
        }
        if (reserva.reservastatus.toLowerCase() === "confirmada") {
          reserva["icone"] = "ni ni-check-bold";
        }
        if (reserva.reservastatus.toLowerCase() === "no show") {
          reserva["icone"] = "ni ni-satisfied";
        }
        if (reserva.reservastatus.toLowerCase() === "checkin") {
          reserva["icone"] = "ni ni-bell-55";
        }
        if (reserva.reservastatus.toLowerCase() === "checkout") {
          reserva["icone"] = "ni ni-send";
        }
        if (reserva.reservastatus.toLowerCase() === "cancelada") {
          reserva["icone"] = "ni ni-fat-remove";
        }
      });
    },
  },

  methods: {
    async requisitarHoteis() {
      const { data } = await HotelServico.listarTodos();
      this.hoteis = data.map((item) => {
        return {
          nome: item.nome,
          value: item.idhotel,
        };
      });
    },
    numeroTelefone(num){
      return num.replace(' ', '').replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
    },
    async requistarPremios() {
      const { data } = await PremioServico.listarPorHotel(this.hotelselecionado);
      let premios = new Array();
      let normal = ({ name, title, available, idpremio }) => {
        return {
          name: name,
          available: available,
          type: 'normal',
          idpremio: idpremio,
          
        };
      };
      let jantar = ({ name, title, available,idpremio }) => {
        return {
          name: name,
          available: available,
          type: 'jantar',
          idpremio:idpremio,
         
        };
      };
      let hospedagem = ({ name, title, available,idpremio }) => {
        return {
          name: name,
          available: available,
          type: 'hospedagem',
          idpremio:idpremio,
        };
      };        
      data.map((vantagem) => {
        if (vantagem.nome.toLowerCase() === "jantar") {
          premios.push(
            jantar({
              name: "Jantar",
              idpremio: vantagem.idpremio, 
              available:vantagem.ativo === true ?
                                this.nivel === vantagem.nivel ?
                                  this.pontosdisponiveis >= vantagem.pontuacao? true : false
                                :false
                        :false
            })
          );
        } else if (
          vantagem.nome.toLowerCase() === "hospedagem" ||
          vantagem.nome.toLowerCase() === "diaria"
        ) {
          premios.push(
            hospedagem({
              name: "Hospedagem",
              idpremio: vantagem.idpremio, 

              available:vantagem.ativo === true ?
                                this.nivel === vantagem.nivel ?
                                  this.pontosdisponiveis >= vantagem.pontuacao? true : false
                                :false
                        :false
            })
          );
        } else {
          premios.push(
            normal({
              name: vantagem.nome,
              idpremio: vantagem.idpremio, 
              available:vantagem.ativo === true ?
                                this.nivel === vantagem.nivel ?
                                  this.pontosdisponiveis >= vantagem.pontuacao? true : false
                                :false
                        :false
            })
          );
        }
      });
      this.vantagens = premios;
    },
    
    corDaReserva(status) {
      if (status.toLowerCase() === "registrada") {
        return "#F4C887";
      }
      if (status.toLowerCase() === "confirmada") {
        return "#A1DAAD";
      }
      if (status.toLowerCase() === "no show") {
        return "#A0D2EB";
      }
      if (status.toLowerCase() === "checkin") {
        return "#E991F5";
      }
      if (status.toLowerCase() === "checkout") {
        return "#F58678";
      }
      if (status.toLowerCase() === "cancelada") {
        return "#FFC8CE";
      }
    },
    link(to) {
      this.$router.push(to);
    },
    formatarData(data) {
      return new Date(data).toLocaleDateString();
    },
    formatarDataHoras(data) {
      return (
        new Date(data).toLocaleDateString() +
        " " +
        new Date(data).toLocaleTimeString()
      );
    },
    closeAction() {
      this.dialogAction = false;
    },
    openAction(item) {
      console.log(item);
      this.currReserva = Object.assign({}, item);
      this.dialogAction = true;
    },
    async requisitarPromotores() {
      if(this.idusuariotipo === 4){
        // const agente = await UsuarioServico.listar(parseInt(localStorage.getItem('idusuario')))
        // const zonasagente = await this.requisitarZonaUsuario(agente.data[0].idpessoa)
        // const { data } = await PromotorServico.listarTodos();
        // this.promotores = await Promise.all(data.map(async (item) => {
        //   let zonas = await this.requisitarZonaUsuario(item.idpessoa)
        //   item['zonas'] = zonas
        //   return item
        // }));
        // let promotoresmesmazona = new Array()
        // this.promotores.map(promotor => {
        //   promotor.zonas.map(zona => {
        //     if (zonasagente.includes(zona)){
        //       promotoresmesmazona.push(promotor)
        //     }
        //   })
        // })
        // this.promotores = promotoresmesmazona
        const {data} = await PromotorServico.listarPorHotel(this.hotelselecionado)
        const agente = await UsuarioServico.listar(parseInt(localStorage.getItem('idusuario')))
        let promotoresmesmacidade = new Array()
        for(var ç=0; ç<data.length;ç++){
          let item = data[ç]
          if(item.idcidade === agente.data[0].idcidade){
            let zonaspromotor = await this.requisitarZonaUsuario(item.idpessoa)
            let zonasagente = await this.requisitarZonaUsuario(agente.data[0].idpessoa)
            zonaspromotor.map(zona => {
              if(zonasagente.includes(zona)){
                const promotorobj =  {nome: item.nome, celular: item.numero == null ? '27999999999' : item.numero}
                promotoresmesmacidade.push(promotorobj)
              }
            })
          }
        }
        console.log('promotoresmesmacidade', promotoresmesmacidade)
        this.promotores = promotoresmesmacidade
      }else if(this.idusuariotipo === 1 || this.idusuariotipo === 2){
        console.log('id usuario tipo adm adm hotel')
        const { data } = await PromotorServico.listarTodos();
        this.promotores = data
      }
    },
    async requisitarZonaUsuario(id){
      const {data} = await LocalizacaoServico.listarZonaPorUsuario(id)
      return data.map(zona => zona.idzona)
      //pegar as zonas associadas ao idpessoa desse usuario na tabela pessoazona ela em inserir em this.zona
    },
    async requisitarPontosDisponiveis(idusuario, idhotel){
      const {data} = await PremioServico.saldoAgente(idusuario, idhotel)
      this.nivel = this.getNivel(data.dsnivel)
      this.pontosdisponiveis = data.qtdepontos
    },
    async requisitarUsuario(id){
      const {data} = await UsuarioServico.listar(id)
      return data[0]
    },
    async alteracaoCampoHoteis() {
      localStorage.setItem('hotel', this.hotelselecionado)
      const { data } = await ReservaServico.listarPorHotel({
        idhotel: this.hotelselecionado,
      });
      this.reservas = data;
      await this.requisitarPontosDisponiveis(this.idusuario, this.hotelselecionado)
      this.nomehotel = this.hoteis.filter(el => el.value === this.hotelselecionado)[0].nome
      await this.requistarPremios()
      await this.requisitarPromotores(this.hotelselecionado)
    },
     getNivel(nivel){
      switch(nivel){
        case 'Ouro':
          return 3
        case 'Prata':
          return 2
        case 'bronze':
          return 1
      }
    }
  },
  async mounted() {
    this.idusuario = parseInt(localStorage.getItem('idusuario'))
    this.idusuariotipo = parseInt(localStorage.getItem('idusuariotipo'))
    await this.requisitarHoteis();
    const currsearch = parseInt(localStorage.getItem('hotel'))
    if(currsearch){
      this.hotelselecionado = currsearch
      await this.alteracaoCampoHoteis()
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: auto;
  background: rgba(249, 249, 249, 0);
}

.minicards {
  box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
}

.section-score {
  display: grid;
  grid-template-rows: 0.1fr 0.1fr;
  grid-template-columns: 1fr 0.4fr;
  grid-gap: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;

  .column-1 {
    max-width: 100%;
    grid-column: 1 /2;
    grid-row: 1 /2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: column;
      align-items: start;

      h1 {
        font-size: 1.2rem;
        text-transform: capitalize;
      }

      p {
        font-size: 0.8rem;
        color: #bbb;
        margin: 0;
      }
    }
    > span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5rem 1rem;
      width: 20rem;
      background: white;
      border-radius: 1rem;
      height: 3rem;
      box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
      .unicon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        transform: rotateY(180deg);
      }
      input {
        width: 100%;
        outline: none;
        font-size: 0.7rem;
        text-transform: capitalize;
        color: #bbb;
      }
    }
  }

  .column-2 {
    max-width: 100%;

    > span {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 1rem;
      padding: 0 1rem;
      height: 3rem;
      box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
      > div {
        margin: 0 0.5rem;
        h1 {
          font-size: 0.8rem;
          text-transform: capitalize;
        }
        p {
          font-size: 0.6rem;
          margin: 0;
          color: #bbb;
        }
      }
    }
  }

  .panel-score {
    grid-column: 1 /2;
    grid-row: 2 /3;
    display: grid;

    .plans {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      height: auto;
      max-width: 100%;
      position: relative;
      .plan {
        position: relative;
        width: 31%;
        max-height: 5em;
        padding: 1rem;
        box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
        border-radius: 1rem;
        display: flex;
        align-items: center;
        background: white;
        @media screen and (max-width: 768px){
          max-height: unset;
          height: fit-content;
        }
        .unicon {
          margin-right: 1rem;
        }
        .plan-info {
          text-transform: capitalize;
          h1 {
            font-size: 1.2rem;
          }
          p {
            font-size: 0.8rem;
            color: #bbb;
          }
        }
      }
    }

    .score {
      box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
      border-radius: 1rem;
      padding: 1rem;
      background: white;
      max-width: 100%;
      margin-top: 1rem;
      .hero {
        margin: 20px auto 50px auto;

        .hero-title {
          font-size: 2rem;
          &:hover{
            text-decoration: underline;
            cursor: pointer;
            color: blue
          }
        }
        .hero-subtitle {
          font-size: 1rem;
        }
        .hero-text {
          font-size: 0.8rem;
          color: #bbb;
        }

        button {
          background: #96c714;
          border-radius: 1rem;
          color: white;
          font-weight: bold;
          border: none;
          text-transform: capitalize;
          padding: 0.5rem 1rem;
        }
      }
    }
  }

  .panel-advantage {
    grid-column: 2/3;
    grid-row: 2/3;
    box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
    background: white;
    border-radius: 1rem;
    padding: 1rem;
    max-width: 100%;
    > h1 {
      font-size: 1.2rem;
    }
    > p {
      font-size: 0.8rem;
      color: #bbb;
    }

    ul {
      padding: 0;

      li {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        > span {
          display: flex;
          align-items: center;
          .alt-icon {
            min-height: 3rem;
            min-width: 3rem;
            border-radius: 15px;
            background: #d8d8d8;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .unicon{
              display: flex;
              align-items: center;
              justify-content: center;
            }     
          }
          .vantagem-info {
            h1 {
              font-size: 0.9rem;
              text-transform: capitalize;
            }
            p {
              font-size: 0.8rem;
              margin: 0;
              text-transform: capitalize;
              word-break: wrap;
              line-height: 1rem;
            }
          }
        }
        > button {
          background: #96c714;
          border-radius: 1rem;
          color: white;
          font-weight: bold;
          border: none;
          width: fit-content;
          font-size: 12px;
          text-transform: capitalize;
          padding: 0.2rem 0.5rem;
          margin-left: 0.5rem;
          letter-spacing: 0.02rem;
          &.disabled {
            background: #ccc;
            color: white;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px){
    padding-top: 1rem;
    display: grid;
    grid-template-rows: 0.1fr 1fr 0.1fr auto;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin-bottom: 1.5rem;

    .column-1{
      > span{
        width: fit-content
      }
    }

    .column-2{
      grid-column: 1 /2;
      grid-row: 3/4;
    }

    .panel-score{
      grid-column: 1 /2;
      grid-row: 2 /3;
      .plans{
        .plan{
          flex-direction: column;
        }
      }
    }

    .panel-advantage{
      grid-column: 1/2;
      grid-row: 4/5;
    }
  }
}

.section-request {
  padding-top: 1rem;
  display: grid;
  grid-template-rows: 0.1fr 0.1fr;
  grid-template-columns: 1fr 0.4fr;
  grid-gap: 1.5rem;

  .column-1 {
    grid-column: 1 /2;
    grid-row: 1 /2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: column;
      align-items: start;

      h1 {
        font-size: 1.2rem;
        text-transform: capitalize;
      }

      p {
        font-size: 0.8rem;
        color: #bbb;
        margin: 0;
      }
    }
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      width: 20rem;
      background: white;
      border-radius: 1rem;
      height: 3rem;
      box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
      .unicon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        transform: rotateY(180deg);
      }
      input {
        width: 100%;
        outline: none;
        font-size: 0.7rem;
        text-transform: capitalize;
        color: #bbb;
      }
    }
  }

  .panel-request {
    grid-column: 1 /2;
    grid-row: 2 /3;
    box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
    border-radius: 1rem;
    padding: 1rem;
    background: white;
    max-width: 100%;

    @media screen and (max-width: 768px){
      max-width: 93vw;
    }

    .chip-reserva {
      text-transform: capitalize;
      font-size: 12px;
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 10px;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
      .icone-reserva {
        margin-right: 0.2rem;
      }
    }
    .item-nomehospede {
      font-weight: bold;
      font-size: 0.8125rem !important;
      text-transform: capitalize;
    }
    .item-nomeagencia {
      font-weight: 400;
      font-size: 0.6rem !important;
      text-transform: capitalize;
      letter-spacing: 0.02rem;
    }
  }

  .panel-promoter {
    grid-column: 2/3;
    grid-row: 2/3;
    box-shadow: 0 2px 5px rgba(133, 133, 133, 0.4);
    background: white;
    border-radius: 1rem;
    padding: 1rem 1rem .5rem 1rem;
    max-height: 32rem;
    position: relative;
    max-width: 100%;

    .panel-promoter-header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      justify-content: space-between;
      .promoter-header-info {
        h1 {
          font-size: 1.2rem;
        }
        p {
          font-size: 0.8rem;
          color: #bbb;
          margin: 0;
        }
      }
      .promoter-header-action {
        background: #187fd9;
        font-weight: bold;
        font-size: 1.2rem;
        color: white;
        width: 3rem;
        height: 3rem;
        display: grid;
        place-items: center;
        border-radius: 1rem;
        &:hover {
          font-size: 1.5rem;
          cursor: pointer;
          transition: 0.2s all;
          transform-origin: center;
        }
      }
    }

    ul {
      padding: 0;
      margin-bottom: 1rem;
      max-height: 22rem;
      overflow-y: scroll;
      padding-bottom: 2rem;
     
      li {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        > span {
          display: flex;
          align-items: center;
          .alt-icon {
            min-height: 3rem;
            min-width: 3rem;
            border-radius: 15px;
            background: #d8d8d8;
            margin-right: 0.5rem;
          }
          .promoter-info {
            h1 {
              font-size: 0.9rem;
              text-transform: capitalize;
            }
            .promoter-info-details {
              display: flex;
              flex-direction: column;
              align-items: start;
              input {
                font-size: 0.8rem;
                margin: 0;
                text-transform: capitalize;
                word-break: wrap;
                line-height: 1rem;
                color: #bbb;
              }
            }
          }
        }
        > button {
          background: transparent;
          color: white;
          border: none;
          width: fit-content;
          margin-right: 0.5rem;
        }
      }
    }

    .btn-promoter {
      background: #187fd9;
      font-weight: bold;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: white;
      width: 90%;
      height: 3rem;
      display: grid;
      place-items: center;
      border-radius: 1rem;
      position: absolute;
      bottom: 15px;
    }
    @media screen and (max-width: 768px){
      height: auto;
    }
  }

  @media screen and (max-width: 768px){
    padding-top: 1rem;
    display: grid;
    grid-template-rows: 0.1fr 1fr auto;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin-bottom: 1.5rem;
    max-width: 100%; 

    .column-1{
      grid-column: 1 /2;
      grid-row: 1/2;
    }

    .panel-request{
      grid-column: 1 /2;
      grid-row: 2 /3;
    }

    .panel-promoter{
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }
}

//aux para resetar css indesejado
.v-application p {
  margin-bottom: 0;
}
p,
span {
  user-select: none;
}
</style>











